/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Layout from '../components/Layout';
import NewsletterFormSidebar from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';

export default function AnnualReviews({ data }) {
  const image = getImage(data.heroBg);

  const imgStack = ['linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.7))', image];

  return (
    <Layout>
      <SEO />
      <BgImage
        css={css`
          &::after,
          &::before {
            background-position: 50% 10%;
            background-size: cover;
          }
        `}
        style={{ backgroundPosition: '', backgroundSize: '' }}
        image={imgStack}
      >
        <div>
          <div className="container py-12  md:pb-24 md:pt-24 ">
            <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">
              404: Page Not Found
            </h1>
          </div>
        </div>
      </BgImage>
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap">
          <div className="md:w-3/4 w-full md:border-r border-gray-300 md:pr-6 text-center mt-6">
            <Link to="/">Go Back</Link>
          </div>

          <div className="md:w-1/4 w-full md:pl-6 ">
            <NewsletterFormSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    heroBg: file(relativePath: { eq: "annual-reviews-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
      }
    }
  }
`;
